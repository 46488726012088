.navbar {
    background-color: #DEF1FB !important;
    color: var(--secondary-blue-color);
}
.navbar-dark .navbar-brand {
    color: var(--secondary-blue-color);
    text-align: left;
    font-size: 1em;
}
.navbar-dark .navbar-brand span {
    font-size: 0.75em;
}
.navbar-dark .navbar-nav .nav-link {
    color: var(--secondary-blue-color);
    font-size: 0.8em;
}
.headerLogo-bg{
    max-height: 50px;
}
.navbar-dark .navbar-toggler-icon {
    background-image: url("../images/Hamburger.svg");
}
.customnavlink {
    display: flex;
    justify-content: center;
    gap: 0.25em;
    align-items: center;
    transition: all 0.2s ease-in-out;
}
.customnavlink:hover button {
    text-decoration: none;
    transform: scale(1.1);
}
.customnavlink:hover 
.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link {
    color: var(--main-blue-color) !important;
}
.accountButton {
    display: flex;
    justify-content: center;
    align-items: center;
}
.accountButton:hover .nav-link {
    color: white !important;
}
.logoutBtn {
    margin-left: 1em;
}
.nav-link.active {
    font-weight: bold;
}
.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link {
    color: var(--main-blue-color);
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: var(--main-blue-color);
    text-decoration: underline;
}