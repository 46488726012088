.formSection {
    z-index: 1;
    padding: 3vw 5vw;
    background-color: white;
    border: 2px solid white;
    border-radius: 2em;
    color: var(--secondary-blue-color);
    display: flex;
    flex-direction: column;
    gap: 1em;
    box-shadow: rgba(149, 157, 165, 0.35) 0px 8px 24px;
    z-index: 5;
    max-width: 80vw;
}
.contactPage h1 {
    color: var(--secondary-blue-color);
}
.contactPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 8em;
}

.formSection label {
    text-align: left;
    margin-top: 1em;
    font-weight: bold;
}

.formSection input {
    background: none;
    padding: 0.5em 1em;
    border: none;
    border: 1px solid var(--main-bg-color);
    color: var(--secondary-blue-color);
}

.formSection input:hover {
    color: var(--secondary-blue-color);
}

.formSection input:focus {
    outline: none;
    color: var(--secondary-blue-color);
}

.formSection textarea {
    background: none;
    padding: 0.5em 1em;
    border: 1px solid var(--main-bg-color);
}


.formSection textarea:focus {
    background: var(--main-bg-color);
    outline: none;
    color: var(--secondary-blue-color);
}
.mapSection {
    z-index: 5;
}
h1 {
    display: flex;
    align-items: center;
    gap: 0.25em;
}
.contactPage .content {
    align-items: center;
}