.App {
  text-align: center;
  color: var(--secondary-blue-color);
  background-color: #61bdec36;
}
  
.btn {
  padding: 10px 15px;
  border-radius: 100px;
  color: var(--secondary-blue-color);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.75em;
  gap: 1em;
  transition: all 0.2s ease-in-out;
}

.btn-primary {
  background-color: var(--secondary-green-color);
  color: white;
  border: .1em solid var(--secondary-green-color);
}

.btn-primary:hover, .btn-primary:focus {
  background-color: var(--secondary-green-color);
  border-color: var(--secondary-green-color);
  color: white;
  transform: scale(1.1);
}

.btn-secondary {
  background-color: var(--secondary-blue-color);
  border: .1em solid var(--secondary-blue-color);
  color: white;
}

.btn-secondary:hover, .btn-secondary:focus {
  background-color: var(--secondary-blue-color);
  border-color: var(--secondary-blue-color);
  transform: scale(1.1);
  color: white;
}

.btn-primary:hover svg,.btn-secondary:hover svg {
  color: white;
  transform: rotateZ(45deg);
}
.btn-primary svg,.btn-secondary svg {
  transition: transform 0.2s ease-in-out;
} 
.contentContainer {
  position: relative;
  width: 100vw;
  overflow-x: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  padding-top: 7.5em;
}

.loadingScreen {
  background-color: #DEF1FB;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top:-200%;
  z-index: 2000;
  transition: all 0.5s ease-in-out;
}
.loadingScreen.loading {
  top: 0;
}
.loadingScreen.loading .logo-loading {
  transform: scale(1.1);
}
.loadingScreen.loading .logo-loading {
  transition: all 1s ease-in-out;
}
.logo-loading {
  width: 20vw;
}
.content { display: flex;
  flex-direction: column;
  gap: 2em;
}
.description {
  text-align: justify;
  width: 800px;
  max-width: 95%;
  padding: 2.5em;
  margin-bottom: 2em;
  background-color: white;
  border-radius: 2em;
  box-shadow: rgba(149, 157, 165, 0.35) 0px 8px 24px;
}

.footer {
  padding-top: 2em;
  padding-bottom: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1350px){
  .content {
    flex-direction: row;
  }
}

.kurziva {
  font-style: italic;
}