.slide-custom {
    display: flex;
    max-width: 900px;
    width: 90%;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    padding: 2em;
    background-color: white;
    border-radius: 1em;
}

.images-wrap div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.slide-container {
    width: 100%;
    background-color: transparent;
    z-index: 0;
}
.slide-custom img{
    max-width: 100%;
    border-radius: 1em;
}
.each-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}
h1.title {
    display: block;
    padding-top: 2em;
}

.review {
    display: flex;
    gap: 1em;
    flex-direction: column;
}
.review-content {
    display: "flex";
    flex-direction: column;
}
p {
    margin-bottom: 0
}
@media screen and (min-width: 700px){
    .review {
        flex-direction: row;
        align-items: start !important;
    }
    .review-content {
        align-items: start !important;
    }
}