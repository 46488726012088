.form {
    display: flex;
    flex-direction: column;
    position: relative;
}

.form label {
    font-size: 1em;
    color: var(--secondary-blue-color);
    text-align: left;
    padding-bottom: 0.25em;
    padding-top: 0.5em;
}
.buttonForm {
    width: fit-content;
    align-self: center;
    margin-top: 1em;
}