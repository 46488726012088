.servicesCard {
    width: 300px;
    padding: 3em;
    border-radius: 2em;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.85em;
    height: 100%;
    box-shadow: rgba(149, 157, 165, 0.35) 0px 8px 24px;
}

.content {
    box-sizing: border-box;
}

.servicesPage {
    flex-direction: column;
    justify-content: flex-start;
}
.servicesPage .content {
    align-items: flex-start;
}

.servicesCard p {
    text-align: center;
}

.serviceSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2em;
    height:fit-content;
}

.diagnostika {
    background: rgb(42,110,113);
    background: linear-gradient(135deg, rgba(42,110,113,1) 0%, rgba(0,146,155,1) 100%);
}

.liecba {
    background: rgb(0, 95, 16);
    background: linear-gradient(135deg, rgba(0, 95, 16, 1) 0%, rgba(0, 167, 34, 1) 100%);
}

.odbery {
    background: rgb(139,56,25);
    background: linear-gradient(135deg, rgba(139,56,25,1) 0%, rgba(201,74,26,1) 100%);
}

.pedikura {
    background: rgb(138,138,21);
    background: linear-gradient(135deg, rgba(138,138,21,1) 0%, rgba(212,200,38,1) 100%);
}

.edukacia {
    background: rgb(126,24,119);
    background: linear-gradient(135deg, rgba(126,24,119,1) 0%, rgba(180,30,169,1) 100%);
}

.descSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5em;
}
.serviceImage {
    width: 600px;
    max-width: 80%;
}
@media screen and (min-width: 1000px){
    .content {
      flex-direction: row;
    }
  }
  @media screen and (min-width: 1300px){
    .descSection {
        flex-direction: row;
        padding: 0 3em;
    }
    .serviceImage {
        order: 3;
    }
  }
  @media screen and (min-width: 1600px){
    .content {
      flex-direction: row;
    }
    .serviceSection {
        flex-direction: row;
    }
  }