.toast {
    position: absolute;
    bottom: 1%;
    left: 1%;
    z-index: 10000000;
    border: none !important;
}
.toast-header {
    background-color: var(--secondary-blue-color);
    border: none;
    color: white;
}
.toast-body {
    background-color: #DEF1FB;
    border: none;
    color: var(--secondary-blue-color);
}