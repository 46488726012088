.avatarCard {
    position: relative;
}

.avatarCard:hover {
    cursor: pointer;
}
.avatar {
    width: 400px;
    position: relative;
    border-radius: 2em;
    box-shadow: rgba(149, 157, 165, 0.35) 0px 8px 24px;
}
.avatarText {
    position: absolute;
    bottom: 50px;
    z-index: 100;
    text-align: center;
    width: 100%;
    padding: 1em;
    font-weight: bold;
    background-color: var(--secondary-blue-color);
    color: white;
    line-height: 0.25em;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

.flip-card {
        width: 400px;
        height: 532.73px;
        border-radius: 2em;
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    border-radius: 2em;
    position: relative;
    width: 400px;
    height: 532.73px;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    border-radius: 2em;
    position: absolute;
    cursor: pointer;
    width: 400px;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    -webkit-perspective: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    visibility:visible;
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  /* Style the back side */
  .flip-card-back {
    background-color: var(--secondary-blue-color);
    color: white;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .cardDescription {
      max-width: 80%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 1em;
  }
  .cardButton {
    z-index: 100;
  }