.infoSection {
    text-align: left;
    z-index: 1;
    max-width: 80%;
}
h1 {
    font-weight: bold;
    margin-bottom: 40px;
}
.landingPage {
    background-color: var(--main-bg-color);
}
.landingButtons {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.openingHours {
    z-index: 1;
    padding-top: 2em;
    color: var(--secondary-blue-color);
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    border-radius: 1em;
    width: fit-content;
}
.dayHour {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.scrollpartners {
    color: var(--main-bg-color);
}
.landingPageImage {
    width: 800px;
    max-width: 100%;
}
.landingButtons {
    font-size: 1.125em;
}
.openingHours a {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 0.25em;
}
.openingHours a:hover {
text-decoration: underline !important;}

.landingPageWrapper {
    overflow-x: hidden;
}
.landingPageWrapper .contentContainer {
    position: relative;
    height: auto;
}
.landingPageScrollDown {
    position: absolute;
    bottom: 2%;
    color: var(--secondary-blue-color);
}
.partnerLogos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
}
img.partnerLogo {
    height: auto;
    width: 20em;
    max-width: 60%;
    padding: 1em;
    background-color: white;
    border-radius: 1em;
    box-shadow: rgba(149, 157, 165, 0.35) 0px 8px 24px;
}
img.partnerLogo.dovera {
    height: auto;
    width: 15em;
    max-width: 60%;
}
img.partnerLogo.union {
    height: auto;
    width: 10em;
    max-width: 60%;
}
.partners {
}
.partners h1 {
    padding-bottom: 1em;
}

.openHours {
    display: flex;
    gap: 1em;
}
.arrow {
    transition: all 0.1s ease-in-out;
}
.arrow.open {
    transform: rotate(180deg);
}
.rating {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding-bottom: 1em;
}
.stars {
    color: gold;
}

@media screen and (min-width: 500px){
    .landingButtons{
        flex-direction: row;
    }
  }
  @media screen and (min-width: 1350px){
    .landingPage.contentContainer{
        flex-direction: row;
        justify-content: center;
        padding-top: 3em;
    }
  }
