.aboutPage {
    display: flex;
    flex-direction: column;
    padding-top: 7em;
    justify-content: flex-start;
}
.footer {
    padding-top: 5em;
}
.teamPage .content {
    max-width: 80vw;
}
.cardsContainer {
    display: flex;
    gap: 2em;
    flex-direction: column;
    align-items: center;
} 
@media screen and (min-width: 1300px){
    .cardsContainer {
        flex-direction: row;
    } 
}