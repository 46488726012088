.modal-content {
    background-color: #DEF1FB;
    border-radius: 1em;
    border: none;
    padding: 1em;
    color: var(--secondary-blue-color);
}
.modal-content a {
    color: white;
}
.modal-header {
    border: none;
}
.modal-footer {
    border: none;
}
.inputName {
    background: none;
    border: none;
    border-bottom: 0.1em solid var(--secondary-green-color);
    color: var(--secondary-green-color);
    width: 100%;
    padding: 1em 2em;
    margin-bottom: 1em;
}

.inputName:focus {
    border: 0.1em solid var(--secondary-green-color);
    background: var(--secondary-green-color);
    color: var(--main-bg-color);
    border-radius: 100px;
    outline: none;
}
.modalBody a {
    font-weight: bold;
    color: var(--secondary-blue-color) !important;
}